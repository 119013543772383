import 'core-js/stable';
import {createBehavior} from '@area17/a17-behaviors';
import Cookies from 'js-cookie';

const newsletter = createBehavior('newsletter',
    {
        removeOverlay() {
            document.querySelector('.newsletter-iframe').style.zIndex = '0';
            if (this.overlay) {
                this.overlay.remove();
            }
        },

        async postFormDataAsJson(actionUrl, formData) {
            const fetchOptions = {
                method: 'POST',
                body: formData,
            };

            const response = await fetch(actionUrl, fetchOptions);
            if (!response.ok) {
                const error = await response.json();
                throw error.message;
            }

            return response.json();
        },

        submitNewsletter(e) {
            e.preventDefault();

            this.updateResponseMessage('');

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(this.form.dataset.captchaSiteKey, {
                    action: 'submit',
                }).then(async (token) => {

                    const formData = new FormData(this.form);
                    formData.append('g-recaptcha-response', token);

                    return this.postFormDataAsJson(
                        window.SITE_URL + 'actions/co/newsletter/submit',
                        formData,
                    );
                }).then((response) => {
                    this.updateResponseMessage(response.message);

                    let componentContent =
                        document.querySelector('.newsletter-mini-content') == null
                            ? document.getElementById('mo-subscribe')
                            : document.querySelector('.newsletter-mini-content');

                    componentContent.style.visibility = 'hidden';

                    let isNewsletterPage = window.location.pathname.includes('/newsletter');
                    let submitSuccessPlacement = isNewsletterPage
                        ? 'Newsletter Page'
                        : 'Article In Body';
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'newsletter_submit_success',
                        form_placement: submitSuccessPlacement,
                    });

                    this.cookieSubscriber();
                }).catch((error) => {
                    this.updateResponseMessage(error);
                });
            });
        },

        updateResponseMessage(messageText) {
            this.messageLocation.innerHTML = messageText;
        },

        addListeners() {
            if (this.overlay) {
                this.overlay.addEventListener('mouseover', (e) => {
                    this.removeOverlay();
                });
            }

            this.$node.addEventListener('submit', (e) => this.submitNewsletter(e), false);
        },

        cookieSubscriber() {
            Cookies.set('moSubscriber', 'true', {
                expires: 7,
                secure: true,
                sameSite: 'Strict',
            });
        },

        resetCsrfToken() {
            const endpoint = window.SITE_URL + 'actions/co/general/get-csrf-token';

            fetch(endpoint)
                .then(response => response.json())
                .then(data => {
                    const csrfFormFields = document.querySelectorAll('[name="CO_CSRF"]');
                    csrfFormFields.forEach(csrfFormField => {
                        csrfFormField.value = data.csrfTokenValue;
                    });
                })
                .catch(error => console.error('Error:', error));
        },
    },
    {
        init() {
            this.form = this.getChild('form') ? this.getChild('form') : this.$node.querySelector('form');
            this.overlay = this.$node.querySelector('.newsletter-overlay');
            this.messageLocation = this.$node.querySelector('.response-message');
            this.form.querySelector('[type="submit"]').disabled = false;
            this.resetCsrfToken();
            this.addListeners();

        },
    },
);

export default newsletter;
