import {createBehavior} from '@area17/a17-behaviors';

const registrationForm = createBehavior('registrationForm',
    {
        submitForm(e) {
            e.preventDefault();
            const endpoint = window.SITE_URL + 'connect/api/user/update';
            const formData = new FormData(this.$node);
            const data = {};
            formData.forEach((value, key) => data[key] = value);
            return fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    // if destination param is in the url then redirect to that page
                    const urlParams = new URLSearchParams(window.location.search);
                    if (urlParams.has('destination')) {
                        window.location.href = urlParams.get('destination');
                    }
                    window.location.href = this.$node.dataset.redirect;
                })
                .catch((error) => {
                    console.error('Error:', error.message);
                    this.$node.innerHTML = 'An unexpected error has occurred. Please try again later.';
                });
        },

        handleBusinessEmailToggle() {
            const businessEmailRadios = this.$node.querySelectorAll('[name="businessEmailCorrect"]');
            const businessEmailField = this.$node.querySelector('[data-business-email-field]');

            businessEmailField.style.display = 'none';

            businessEmailRadios.forEach(radio => {
                radio.addEventListener('change', () => {
                    // Show the field only when "No" is selected
                    businessEmailField.style.display = radio.value === 'No' ? 'block' : 'none';
                });
            });
        }
    },
    {
        init() {
            this.$node.addEventListener('submit', this.submitForm);

            this.handleBusinessEmailToggle();
        }
    }
);

export default registrationForm;